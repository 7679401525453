import React from 'react';
import ReactGA from 'react-ga';
import { StatusBar } from 'expo-status-bar';
import { Platform, Dimensions, StyleSheet, Text, ScrollView, View, Image, TouchableOpacity, Linking } from 'react-native';

import Header from './components/Header';
import ScreenRender from './screens/Screens';
import ContactFooter from './components/ContactFooter';

import { COLORS } from './constants/Colors';


ReactGA.initialize('UA-99567338-2');

export default function App() {
  const [screen, setScreen] = React.useState("Gallery");

  return (
    <View style={styles.container}>
      <Header screen={screen} setScreen={setScreen} />
      <ScreenRender screen={screen} />
      <ContactFooter />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: COLORS.BodyBackgroundColor,
    paddingBottom: 150,
  },
});
