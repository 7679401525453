import React from 'react';
import { Dimensions, StyleSheet, FlatList, Text, View, Image, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';

import { COLORS } from '../constants/Colors';
import { PHOTOS } from '../photos/';


const ChangePageButton = (props) => {
  const handler = props.handler;
  const text = props.text;
  const style = props.style;

  return (
    <TouchableOpacity style={styles.pageButton} onPress={handler}>
      <Text style={styles.pageButtonText}>{text}</Text>
    </TouchableOpacity>
  )
}

const PrevPageButton = (props) => {
  return <ChangePageButton handler={props.handler} text="<" />
}

const NextPageButton = (props) => {
  return <ChangePageButton handler={props.handler} text=">" />
}

const LegendPhoto = props => {
  const text = props.text;

  return (
    <View style={styles.viewLegendPhoto}>
      <Text style={styles.textLegendPhoto}>{text}</Text>
    </View>
  )
}

const window = Dimensions.get("window");

class TouchablePhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legend: false,
      dimensions:  props.dimensions,
    };
    this.source = props.source;
    this.legend = props.legend;
    this.setSelected = props.setSelected;
    this.index = props.index;

    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.onChangeDimension = this.onChangeDimension.bind(this);
    this.legendItem = <LegendPhoto text={props.legend} />
  }

  photoImageStyle() {
    return {
      height: this.state.dimensions.height*HeightRatioPhotoGallery,
      width: this.state.dimensions.width*WidthRatioPhotoGallery,
      resizeMode: 'cover',
    }
  }

  onChangeDimension({ window }) {
    this.setState({
      dimensions: window,
    });
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onChangeDimension);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onChangeDimension);
  }

  handleOnMouseEnter() {
    this.setState({
      legend: true,
    });
  }

  handleOnMouseLeave() {
    this.setState({
      legend: false,
    });
  }

  render() {
    return (
      <TouchableOpacity
      onMouseEnter={this.handleOnMouseEnter}
      onMouseLeave={this.handleOnMouseLeave}
      onPress={() => this.setSelected(this.index)}>
        <Image
        source={this.source}
        style={this.photoImageStyle()}
        resizeMethod="resize" />
        {this.state.legend ? this.legendItem : null}
      </TouchableOpacity>
    )
  }
};

function getCommonPhotos() {
  return PHOTOS.common;
}

const commonPhotos = getCommonPhotos();
commonPhotos.sort((a, b) => {
  var legendA = a.legend.toUpperCase(); // ignore upper and lowercase
  var legendB = b.legend.toUpperCase(); // ignore upper and lowercase
  if (legendA < legendB) {
    return -1;
  }
  if (legendA > legendB) {
    return 1;
  }

  return 0;
});
const commonLegends = [... new Set(commonPhotos.map(photo => photo.legend))];

const photosPerPage = 15;

const lengthCommonPhotos = commonPhotos.length;
const maxPage = Math.floor(lengthCommonPhotos/photosPerPage);

const initialPage = 0;
function reducer(page, action) {
  switch (action) {
    case 'next':
      return (page + 1) % (maxPage + 1);
    case 'prev':
      return (page == 0) ? (maxPage) : page - 1;
    default:
      throw new Error();
  }
}

export default function GalleryScreen(props) {
  const [page, goPage] = React.useReducer(reducer, initialPage);
  const [place, setPlace] = React.useState("");
  const [indexZoomedPhoto, setIndexZoomedPhoto] = React.useState(null);

  function keyArrowHandler(event) {
    if (event.key === "ArrowLeft") {
      goPage('prev');
    } else if (event.key === "ArrowRight") {
      goPage('next');
    };
  }

  React.useEffect(() => {
    if (indexZoomedPhoto == null) {
      document.addEventListener('keydown', keyArrowHandler);
    } else {
      document.removeEventListener('keydown', keyArrowHandler);
    }
    return () => {document.removeEventListener('keydown', keyArrowHandler)};
  },
  [indexZoomedPhoto]);

  const renderItem = ({ item, index }) => {
    let shouldReturn = false;

    if (place) {
      if (item.legend == place)
        shouldReturn = true;
    } else {
      if (index >= page*photosPerPage && index < page*photosPerPage+photosPerPage)
        shouldReturn = true;
    }

    if (shouldReturn)
      return (
          <TouchablePhoto
          index={index}
          source={item.image}
          legend={item.legend}
          dimensions={Dimensions.get("window")}
          setSelected={setIndexZoomedPhoto} />
        );

  };

  if (indexZoomedPhoto != null) {
    return (
      <ZoomedImage
      source={commonPhotos[indexZoomedPhoto].image}
      legend={commonPhotos[indexZoomedPhoto].legend}
      indexZoomedPhoto={indexZoomedPhoto}
      setIndexZoomedPhoto={setIndexZoomedPhoto}
      goPage={goPage} />
    )
  } else {
    return (
      <View style={props.style}>
        <View style={styles.photosView}>
          <PrevPageButton handler={() => goPage("prev")} />
          <FlatList
          numColumns={5}
          data={commonPhotos}
          extraData={[page, place]}
          renderItem={renderItem}
          keyExtractor={(item, index) => index + index*page} />
          <NextPageButton handler={() => goPage("next")} />
        </View>
        <Text style={styles.zoomedPhotoTextHint1}>Haga click en una foto para ampliarla</Text>
        <Text style={styles.zoomedPhotoTextHint2}>Puede cambiar de página utilizando las flechas del teclado o presionando los botones laterales.</Text>
      </View>
    )
  }
};

const ZoomedImage = ({source, legend, indexZoomedPhoto, setIndexZoomedPhoto, goPage}) => {
  if (indexZoomedPhoto == null) return null;

  function prevIndex() {
    indexZoomedPhoto > 0
    ? setIndexZoomedPhoto(indexZoomedPhoto - 1)
    : setIndexZoomedPhoto(commonPhotos.length - 1);
    if (indexZoomedPhoto % photosPerPage == 0) goPage("prev");
  }

  function nextIndex() {
    setIndexZoomedPhoto((indexZoomedPhoto+1) % commonPhotos.length);
    if ((indexZoomedPhoto+1) % photosPerPage == 0) goPage("next");
  }

  function keyArrowHandlerIndex(event) {
    if (event.key === "ArrowLeft") {
      prevIndex();
    } else if (event.key === "ArrowRight") {
      nextIndex();
    } else if (event.key === "Escape") {
      setIndexZoomedPhoto(null);
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', keyArrowHandlerIndex);
    return () => {document.removeEventListener('keydown', keyArrowHandlerIndex)};
  },
  [indexZoomedPhoto]);

  return (
    <View style={styles.screenContainerStyle}>
      <View style={styles.zoomedView}>
        <PrevPageButton handler={prevIndex} />
        <TouchableOpacity style={styles.zoomedTouchable} onPress={() => setIndexZoomedPhoto(null)}>
          <Image source={source} style={styles.zoomedPhoto} />
          <Text style={styles.zoomedPhotoTextLegend}>{legend}</Text>
        </TouchableOpacity>
        <NextPageButton handler={nextIndex} />
      </View>
      <View style={styles.zoomedHintsView} >
        <Text style={styles.zoomedPhotoTextHint1}>Haga click en la foto para cerrarla o presione {"<"}Escape{">"}</Text>
        <Text style={styles.zoomedPhotoTextHint2}>Puede desplazarse utilizando las flechas del teclado o presionando los botones laterales.</Text>
      </View>
    </View>
  )
}

const WidthRatioPhotoGallery = 0.16;
const HeightRatioPhotoGallery = 0.2;

const styles = StyleSheet.create({
  screenContainerStyle: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: 'center',
    textAlign: 'center',
  },
  descriptionView: {
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  descriptionText: {
    color: COLORS.BodyTextColor,
    fontSize: 24,
    marginBottom: 8,
  },
  photosView: {
    flexDirection: 'row',
    borderTopWidth: 32,
    borderBottomWidth: 32,
  },
  pageButton: {
    backgroundColor: 'black',
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  pageButtonText: {
    fontSize: 36,
    fontWeight: 'bold',
    color: 'white',
  },
  viewLegendPhoto: {
    backgroundColor: COLORS.HeaderBackgroundColor,
    paddingTop: 4,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '20%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLegendPhoto: {
    color: COLORS.HeaderTextColor,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  zoomedView: {
    flexDirection: 'row',
    height: "90%",
    width: "100%",
    marginTop: 16,
    justifyContent: 'center',
  },
  zoomedTouchable: {
    width: "80%",
    height: "100%",
    borderTopWidth: 32,
    borderBottomWidth: 32,
  },
  zoomedPhoto: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  zoomedPhotoText: {
    color: COLORS.BodyTextColor,
    textAlign: 'center',
    fontSize: 16,
    marginTop: 8,
  },
  zoomedPhotoTextLegend: {
    color: COLORS.BodyTextColor,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 4,
  },
  zoomedPhotoTextHint1: {
    color: COLORS.BodyTextColor,
    textAlign: 'center',
    fontSize: 16,
    marginTop: 8,
  },
  zoomedPhotoTextHint2: {
    color: COLORS.BodyTextColor,
    textAlign: 'center',
    fontSize: 16,
    marginTop: 0,
  },
  zoomedHintsView: {
    width: '100%',
    paddingBottom: 150,
    paddingHorizontal: 32,
    backgroundColor: COLORS.BodyBackgroundColor,
  }
})
