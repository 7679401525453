import React from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import Icon from 'react-native-vector-icons/Entypo';


const ContactButton = ({url, src, style}) => {
  return (
    <TouchableOpacity accessibilityRole="link" href={url} target="_blank">
        <Image source={src} style={style} />
    </TouchableOpacity>
  )
};

const WhatsappButton = () => (
  <ContactButton
  url="https://wa.me/56983655707"
  src={require("../assets/contactIcons/whatsapp-icon.png")}
  style={styles.whatsappIcon}/>
);

const FacebookButton = () => (
  <ContactButton
  url="https://www.facebook.com/cortinasroller.laserena.3"
  src={require("../assets/contactIcons/facebook-icon.png")}
  style={styles.facebookIcon}/>
);

const EmailButton = () => {
  const url = "mailto:cortinasrollerlaserena@hotmail.com";

  return (
    <TouchableOpacity accessibilityRole="link">
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <Icon name={'email'} size={55} style={styles.emailIcon} />
      </a>
    </TouchableOpacity>
  )
}

export default function Contact() {
  return (
    <View style={styles.contactView}>
      <WhatsappButton />
      <FacebookButton />
    </View>
  )
};

const styles = StyleSheet.create({
  contactView: {
    position: 'fixed',
    padding: 8,
    right: 0,
    bottom: 0,
    backgroundColor: '#161e63',
    borderTopLeftRadius: 24,
    alignItems: 'center',
  },
  emailIcon: {
    color: 'lightgray',
  },
  facebookIcon: {
    width: 65,
    height: 65,
  },
  whatsappIcon: {
    width: 55,
    height: 55,
    tintColor: '#45f386',
  },
});
