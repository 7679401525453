import React from 'react';
import { Dimensions, Linking, StyleSheet, View, TouchableOpacity, Text } from 'react-native';

import { COLORS } from '../constants/Colors';

const window = Dimensions.get("window");

const Link = props => {
  const focused = props.currentScreen === props.link;

  return (
    <TouchableOpacity
    onPress={() => props.setScreen(props.link)}
    style={varStyles.touchableLink(focused)}>
      <Text style={varStyles.textLink(focused)}>{props.text}</Text>
    </TouchableOpacity>
  );
};

const Links = props => {
  return (
    <View style={styles.links}>
      <Link text="GALERÍA" link="Gallery" currentScreen={props.currentScreen} setScreen={props.setScreen}/>
      <Link text="CONTACTO" link="Contact" currentScreen={props.currentScreen} setScreen={props.setScreen}/>
      <Link text="OPINIONES" link="Reviews" currentScreen={props.currentScreen} setScreen={props.setScreen}/>
      <Link text="CLIENTES" link="Clients" currentScreen={props.currentScreen} setScreen={props.setScreen}/>
    </View>
  )
};

export default function Header(props) {
  return (
    <View style={styles.header}>
      <Text style={styles.title}>Cortinas Roller La Serena</Text>
      <Text style={styles.title}>MZROLLER</Text>
      <View style={styles.separator} />
      <Links currentScreen={props.screen} setScreen={props.setScreen} />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: COLORS.HeaderBackgroundColor,
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
  },
  title: {
    color: COLORS.HeaderTextColor,
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  separator: {
    borderColor: COLORS.HeaderTextColor,
    borderBottomWidth: 1,
    width: '90%',
  },
  links: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: 4,
  },
});

const varStyles = {
  touchableLink : focused => ({
    padding: 12,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: focused ? '#26292F' : 'none',
  }),
  textLink : focused => ({
    color: focused ? COLORS.HeaderFocusedTextColor : COLORS.HeaderTextColor,
    fontWeight: 'bold',
    fontSize: Math.pow(window.width, 0.45),
  }),
}