import React from 'react';
import ReactGA from 'react-ga';
import { StyleSheet } from 'react-native';

import GalleryScreen from './GalleryScreen'
import ContactScreen from './ContactScreen'
import ReviewsScreen from './ReviewsScreen'
import ClientsScreen from './ClientsScreen'


export default function ScreenRender(props) {
  switch (props.screen) {
    case "Gallery":
      ReactGA.modalview('Galería');
      return <GalleryScreen style={styles.screenContainerStyle} />;
    case "Contact":
      ReactGA.modalview('Contacto');
      return <ContactScreen style={styles.screenContainerStyle} />;
    case "Reviews":
      ReactGA.modalview('Opiniones');
      return <ReviewsScreen style={styles.screenContainerStyle} />;
    case "Clients":
      ReactGA.modalview('Clientes');
      return <ClientsScreen style={styles.screenContainerStyle} />;
  }
}

const styles = StyleSheet.create({
  screenContainerStyle: {
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 24,
    width: '75%',
  },
});
