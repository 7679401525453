import React, { Component } from 'react';
import { Dimensions, StyleSheet, Text, ScrollView, View, Image, TouchableOpacity } from 'react-native';
import StarReview from 'react-native-stars';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { COLORS } from '../constants/Colors';


const Quotation = <Text style={{fontSize: 36}}>"</Text>;

const Review = (props) => {
  const author = props.author;
  const relativeTime = props.relativeTime;
  const rating = props.rating;
  const text = props.text;

  const starSize = 32;

  return (
    <View style={styles.reviewView}>
      <Text><b>{author}</b>:</Text>
      <Text style={styles.reviewText}><b>{Quotation}{text}{Quotation}</b></Text>
      <View style={styles.starsView}>
        <StarReview
        display={rating}
        fullStar={<Icon name={'star'} style={[styles.starStyle]} size={starSize}/>}
        emptyStar={<Icon name={'star-outline'} style={[styles.starStyle, styles.emptyStarStyle]} size={starSize}/>}
        halfStar={<Icon name={'star-half'} style={[styles.starStyle]} size={starSize}/>}
        />
        <Text><b>{rating.toFixed(1)}/5.0</b></Text>
      </View>
    </View>
  )
}

const Reviews = (props) => {
  const reviews = [];
  const data = props.data;

  data.map(review => {
    reviews.push(
      <Review
      key={review.author_name}
      author={review.author_name}
      relativeTime={review.relative_time_description}
      rating={review.rating}
      text={review.text} />
    )
  });

  return (
    <View style={styles.reviewsView}>
      {reviews}
    </View>
  )
}

class BlinkText extends Component {
  constructor(props) {
    super(props);
    this.state = { showText: true }

    setInterval(() => {
      this.setState(previousState => {
        return { showText: !previousState.showText };
      });
    },
      450
    );
  }

  render() {
    let display = this.state.showText ? this.props.text : ' ';
    return (
      <Text style={{ fontSize: 32, fontWeight: 'bold' }}>
        <TouchableOpacity accessibilityRole="link" href="https://search.google.com/local/reviews?placeid=ChIJldG2z2_LkZYRD6VyYdHY2RA&q=Cortinas+Roller+La+Serena+MZROLLER&hl=es&gl=419" target="_blank">
          <Text style={{color: this.state.showText ? 'yellow' : 'orange'}}>
            {this.props.text}
          </Text>
        </TouchableOpacity>
      </Text>
    );
  }
}

export default function ReviewsScreen(props) {
  const reviews = reviewsData.result.reviews;
  const rating = reviewsData.result.rating;
  const userRatingsTotal = reviewsData.result.user_ratings_total;

  return (
    <View style={props.style}>
      <View style={styles.descriptionView}>
        <BlinkText text="Haz click AQUÍ para ver todas las opiniones que nos han dejado en Google." />
      </View>
      <View style={styles.separator} />
      <Text style={styles.descriptionText}>{
          "Para nosotros, la confianza y honestidad es importante para la tranquilidad de nuestr@s clientes.\n" +
          "A continuación, puedes ver algunas de nuestras reseñas:"
      }</Text>
      <Reviews data={reviews} />
      <Text style={styles.poweredBy}>Powered by Google</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  descriptionView: {
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: 8,
    // backgroundColor: 'darkred',
    padding: 8,
  },
  descriptionText: {
    color: COLORS.BodyTextColor,
    fontSize: 24,
  },
  poweredBy: {
    color: 'gray',
    marginBottom: 20,
  },
  reviewsView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'baseline',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  reviewView: {
    backgroundColor: 'lightgray',
    padding: 12,
    borderRadius: 20,
    marginVertical: 8,
    marginHorizontal: 4,
    width: 400,
    height: 275,
  },
  reviewText: {
    fontSize: 16,
    textAlign: 'center',
  },
  starsView: {
    marginTop: 'auto',
  },
  starStyle: {
    color: 'yellow',
    backgroundColor: 'transparent',
    textShadowColor: 'black',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 2,
  },
  emptyStarStyle: {
    color: 'white',
  },
  separator: {
    borderColor: COLORS.HeaderTextColor,
    borderBottomWidth: 1,
    width: '90%',
    marginBottom: 8,
  },
});

// Por ahora estos datos están hardcodeados, se espera poder hacer fetch en el futuro a la API de google.
const reviewsData = {
   "result" : {
      "reviews" : [
         {
            "author_name" : "Françoise O",
            "rating" : 5,
            "text" : "Excelente servicio, tomaron medidas un lunes y el viernes ya estaban instaladas, ademas se nota la buena calidad, hermosas y muy bien instaladas !",
         },
         {
            "author_name" : "Sheyla Avalos",
            "rating" : 5,
            "text" : "Excelente servicio, cotice y en la misma semana ya tenia todo instalado, un cambio de imagen y cariñito para el hogar, se ve precioso, totalmente recomendable.",
         },
         {
            "author_name" : "Barbara Inostroza",
            "rating" : 5,
            "text" : "Contacté a la empresa y fue fácil agendar visita para la instalación de cortinas roller dúo. Trabajo muy serio y prolijo, se cumplió con fecha y horario acordados. Lo recomiendo 100%. Excelente relación precio calidad y están informando constantemente del avance del trabajo.",
         },
         {
            "author_name" : "Yamilet Arias",
            "rating" : 5,
            "text" : "Muy buena atención, el producto maravilloso, quede encantada con las cortinas roler, muy buen servicio. RECOMENDADISIMO",
         },
         {
            "author_name" : "KemiZaki",
            "rating" : 5,
            "text" : "Excelente atención al cliente y calidad de servicio. Me sorprendió la rapidez del trabajo. Quedé muy contenta con el resultado; las cortinas son hermosas, totalmente recomendado.",
         },
         {
            "author_name" : "Maria Brito",
            "rating" : 5,
            "text" : "La instalación fue rápida, cumplen con lo programado...me encantó como quedaron mis cortinas.",
         },
         {
            "author_name" : "Daniela Gonzalez",
            "rating" : 5,
            "text" : "Buen trabajo, dedicado en los detalle, preocupado del entorno. Totalmente recomendable.",
         }
      ]
   }
}
