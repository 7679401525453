import React from 'react';
import { Dimensions, StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';

import { COLORS } from '../constants/Colors';


const window = Dimensions.get('window');

export default function ContactScreen(props) {
  const [dimensions, setDimensions] = React.useState({ window });

  const onChange = ({ window }) => {
    setDimensions({ window });
  }

  React.useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  const map = React.useMemo(() => {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13832.347353956076!2d-71.2352222!3d-29.9194021!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10d9d8d16172a50f!2sCortinas%20Roller%20La%20Serena%20MZROLLER!5e0!3m2!1ses-419!2scl!4v1605751246564!5m2!1ses-419!2scl"
        width={dimensions.window.width*0.85}
        height={dimensions.window.height*0.6}
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    )
  },
  [dimensions]);

  return (
    <View style={props.style}>
      <Text style={styles.description}>
        Nuestras cotizaciones son exclusivamente vía Whatsapp. Llámanos o envíanos un mensaje, para resolver cualquier duda que puedas tener, directamente al <b>+56983655707</b>
      </Text>
      <Text style={styles.description}>
        [ESPECIAL] Dado el contexto COVID-19, las visitas a nuestro showroom deben ser acordadas previamente.
      </Text>
      <View style={styles.googleMap}>
        {map}
      </View>
      <Text style={styles.addressText}><b>Whatsapp</b>: +56983655707</Text>
      <Text style={styles.addressText}><b>Dirección</b>: Guillermo Ulriksen #1627 Esq. con Raúl Bitrán
      </Text>
      <Text style={styles.addressText2}>
      La Serena IV Región
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    color: COLORS.BodyTextColor,
    fontSize: 20,
    textAlign: 'center',
  },
  googleMap: {
    marginTop: 16,
  },
  addressText: {
    color: COLORS.BodyTextColor,
    marginTop: 16,
    fontSize: 24,
    alignText: 'center',
  },
  addressText2: {
    color: COLORS.BodyTextColor,
    fontSize: 24,
    alignText: 'center',
  },
});
