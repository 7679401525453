import React from 'react';
import { Dimensions, StyleSheet, Text, ScrollView, View, Image, TouchableOpacity } from 'react-native';

import { COLORS } from '../constants/Colors';
import { LOGOS } from '../assets/clientsLogos';

const Logos = () => (
  <View style={styles.logosView}>
    <View style={styles.insideView}>
      {LOGOS.banner.map((logo) => (
        <Image key={logo} source={logo} style={styles.bannerLogo} />)
      )}
    </View>
    <View style={styles.insideView}>
      {LOGOS.square.map((logo) => (
        <Image key={logo} source={logo} style={styles.squareLogo} />)
      )}
    </View>
  </View>
)

export default function ClientScreen({ style }) {
  return (
    <View style={style}>
        <Logos />
    </View>
  )
}

const styles = StyleSheet.create({
  descriptionView: {
    textAlign: 'center',
    alignItems: 'center',
    width: '80%',
  },
  descriptionText: {
    color: COLORS.BodyTextColor,
    fontSize: 48,
  },
  logosView: {
    width: '100%',
    backgroundColor: 'gray',
    borderRadius: 20,
    padding: 32,
  },
  insideView: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignContent: 'space-around',
    alignItems: 'baseline',
    width: '100%',
    flexWrap: 'wrap',
    marginVertical: 4,
  },
  bannerLogo: {
    width: 270,
    height: 110,
    resizeMode: 'stretch',
    marginVertical: 4,
  },
  squareLogo: {
    width: 150,
    height: 150,
    resizeMode: 'stretch',
    marginVertical: 4,
  }
});
