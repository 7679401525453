export const PHOTOS = {
   common:[
      {
         image: require("./common/thumbnail/0c9c8ef18a8d67b57e20e6db7e2dfa1d_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/13acb858e202397fc02ed078de188afb_1300x1300.jpg"),
         legend:"Brisas de San Joaquín"
      },
      {
         image: require("./common/thumbnail/1421c02666d511bc5847c2206385d542_1300x1300.jpg"),
         legend:"Pisco Elqui"
      },
      {
         image: require("./common/thumbnail/1a2eb05d48ad6663f467458254c4ae84_1300x1300.jpeg"),
         legend:"Edificio Miró"
      },
      {
         image: require("./common/thumbnail/1f54dce1b5d60e3380e77fb94733562e_1300x1300.jpg"),
         legend:"Hacienda Nova"
      },
      {
         image: require("./common/thumbnail/257420ed6cb0307c28495f5c6b41417f_1300x1300.jpeg"),
         legend:"La Florida"
      },
      {
         image: require("./common/thumbnail/26e935653c9391fa995db0d754fb42c6_1300x1300.jpg"),
         legend:"El Solar de Peñuelas"
      },
      {
         image: require("./common/thumbnail/2c1b62a25e2ff6ad4531858d93044089_1300x1300.jpg"),
         legend:"Mirador del Valle"
      },
      {
         image: require("./common/thumbnail/2f4f1b461b1fe83cc52e26d8ffb8e217_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },
      {
         image: require("./common/thumbnail/3096bb501d06773d5fba9fe717b277ae_1300x1300.jpg"),
         legend:"Brisas de San Joaquín"
      },
      {
         image: require("./common/thumbnail/3444ccb8dbcedb5c6ec61bddadb6b550_1300x1300.jpg"),
         legend:"Edificio Avenida Pacífico"
      },
      {
         image: require("./common/thumbnail/37be1efd257697c9e754a992f129e93e_1300x1300.jpg"),
         legend:"Los Pescadores"
      },
      {
         image: require("./common/thumbnail/3bc584d4ba40464e3ba991d6b9c417cf_1300x1300.jpeg"),
         legend:"Parcela La Serena"
      },
      {
         image: require("./common/thumbnail/4444eef53d1626f28f09db9a8644eac0_1300x1300.jpg"),
         legend:"Mirador del Valle"
      },
      /*{
         image: require("./common/thumbnail/4dbb5b1f2f41987e303d3dded0789294_1300x1300.jpg"),
         legend:"Portal del Valle"
      },*/
      {
         image: require("./common/thumbnail/4f23b34ac7fde13aa0e570b6c856800e_1300x1300.jpg"),
         legend:"Persianas de Exterior"
      },
      {
         image: require("./common/thumbnail/50bb246b4d11136b868812778591c57a_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },
      {
         image: require("./common/thumbnail/519089cf30f5bd800dfd67d95292d790_1300x1300.jpg"),
         legend:"Juan Soldado"
      },
      {
         image: require("./common/thumbnail/52bf8652c8305c7ef0ebecbee927eb45_1300x1300.jpg"),
         legend:"Los Pescadores"
      },
      {
         image: require("./common/thumbnail/578377cd8ba736f23bcd5555a301ea76_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/5c6c70a46738e6157f1b56efd5eee47e_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/5c7884682d2e3717ffd4ad83fbac5b03_1300x1300.jpg"),
         legend:"Altovalsol"
      },
      {
         image: require("./common/thumbnail/5d80654359e5a8fc3a72cd0a304c3991_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/5f1a819baf0838870c944928e945d7b8_1300x1300.jpg"),
         legend:"Persianas de Exterior"
      },
      /*{
         image: require("./common/thumbnail/5ff4ac7536558035128952466f3cd9ab_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },*/
      {
         image: require("./common/thumbnail/6142ac7a3a4feb43de87f0e1991a9924_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/621f88298e1274b98fc322f4949f4a86_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },
      {
         image: require("./common/thumbnail/63a48523780875cac70a65a9bf41b794_1300x1300.jpeg"),
         legend:"Edificio Miró"
      },
      {
         image: require("./common/thumbnail/651c712d358083421ee3e96d8658ca99_1300x1300.jpg"),
         legend:"El Solar de Peñuelas"
      },
      {
         image: require("./common/thumbnail/66e4ee0b0cfd3254dc77e84317797cf8_1300x1300.jpg"),
         legend:"Mirador del Valle"
      },
      {
         image: require("./common/thumbnail/6947eb92a3ff1444a683996a96442d4b_1300x1300.jpg"),
         legend:"Otros"
      },
      {
         image: require("./common/thumbnail/6f8da84e095efa6a0d2901db51c72c71_1300x1300.jpg"),
         legend:"Nova Hacienda"
      },
      {
         image: require("./common/thumbnail/700554526c413a4136a0507e203724e3_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/721a3866da385c90a80b0af5baf3300c_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/7429f091d8343ce6dc7190821018d076_1300x1300.jpg"),
         legend:"Los Pescadores"
      },
      {
         image: require("./common/thumbnail/76efad2f68581177d711381967b458a9_1300x1300.jpg"),
         legend:"Mirador del Valle"
      },
      {
         image: require("./common/thumbnail/79710c93c650af7b56bd5dcceb52f660_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/7a7ce441f34044118267c0d4e0b3307b_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/7efd90e96ba3e9ad6638fb7ecd3666bb_1300x1300.jpg"),
         legend:"Nova Hacienda"
      },
      /*{
         image: require("./common/thumbnail/802fc34c00c02fb333c9ba52548afbdf_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },*/
      {
         image: require("./common/thumbnail/866dc0da28450ccba348760308b8a08a_1300x1300.jpg"),
         legend:"Mirador del Valle"
      },
      {
         image: require("./common/thumbnail/86b823b5896727d6ddbb7b61504362e6_1300x1300.jpg"),
         legend:"Otros"
      },
      {
         image: require("./common/thumbnail/874aacde3f4c463e8c34bd15018e4316_1300x1300.jpeg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/879af382e731bb5bafdeca787506c8fc_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/8bc77a5a1c03375273e03fe0e882bca1_1300x1300.jpeg"),
         legend:"La Florida"
      },
      /*{
         image: require("./common/thumbnail/8c41dd96c39076df7fcd383b53f7180c_1300x1300.jpg"),
         legend:"Oficina Ecomac"
      },*/
      /*{
         image: require("./common/thumbnail/8de04e9f4b618f45f9483b66d3359176_1300x1300.jpg"),
         legend:"Oficina Ecomac"
      },*/
      {
         image: require("./common/thumbnail/93c79a82e2e4711bb3190b15c6fbc0af_1300x1300.jpg"),
         legend:"Avenida Pacífico"
      },
      {
         image: require("./common/thumbnail/95546078697816b9651da819f2d2a9b0_1300x1300.jpg"),
         legend:"Los Pescadores"
      },
      {
         image: require("./common/thumbnail/960ee3623a726df572cbac98ff21f81e_1300x1300.jpg"),
         legend:"Portal del Valle II"
      },
      {
         image: require("./common/thumbnail/96340d24c548795bc607624f6b198790_1300x1300.jpeg"),
         legend:"Serena Oriente"
      },
      {
         image: require("./common/thumbnail/96ed83dc41f6f96b9b176d68afd2d871_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/97ef94130b469986a612b07c41547d23_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      /*{
         image: require("./common/thumbnail/a12f8a3ef0600dd5ce62dcd4bbff9d82_1300x1300.jpeg"),
         legend:"Showroom"
      },*/
      /*{
         image: require("./common/thumbnail/a1747548731f5bad04dcd3e343ef1e62_1300x1300.jpg"),
         legend:"Ampliación La Arboleda"
      },*/
      {
         image: require("./common/thumbnail/a28d71662aab839327dc790f8daf9a86_1300x1300.jpg"),
         legend:"Avenida del Mar"
      },
      /*{
         image: require("./common/thumbnail/a541b4858c3c93c5649d214d643de821_1300x1300.jpeg"),
         legend:"Ampliación La Arboleda"
      },*/
      /*{
         image: require("./common/thumbnail/a649ebfe22e7f6f0563ffea968182c3d_1300x1300.jpg"),
         legend:"Ampliación La Arboleda"
      },*/
      {
         image: require("./common/thumbnail/ab11f8f1c8eccd72c7cdaac94c2a7d16_1300x1300.jpg"),
         legend:"Altovalsol"
      },
      {
         image: require("./common/thumbnail/b02ae51e79cf2d5daf98287fbf59cabf_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/b86369ad7b87fb286c6fed9537126faf_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/bdb123a8419271e2fc53187ee19e690e_1300x1300.jpeg"),
         legend:"Edificio Miró"
      },
      {
         image: require("./common/thumbnail/bf8f4143ed1867ed920b34e608aaf638_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/c21fcc8c24965f7b47a219ac354d4e7a_1300x1300.jpg"),
         legend:"Parcela La Florida"
      },
      {
         image: require("./common/thumbnail/c4da1b334c7717fa955ac6e235b5e1ab_1300x1300.jpg"),
         legend:"Edificio Avenida Pacífico"
      },
      {
         image: require("./common/thumbnail/c5b2e049e04abd15d729a20d5f7862ba_1300x1300.jpg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/c8106f434e692d00738a4a57d0983b75_1300x1300.jpg"),
         legend:"Brisas de San Joaquín"
      },
      {
         image: require("./common/thumbnail/c9825db66de20d7606cbbcd8d0925d38_1300x1300.jpg"),
         legend:"Departamento La Serena"
      },
      {
         image: require("./common/thumbnail/d2c52138d25e481e6749a178f0e47acf_1300x1300.jpg"),
         legend:"Altovalsol"
      },
      {
         image: require("./common/thumbnail/d790a37fc03c744ea767b532e51993b1_1300x1300.jpg"),
         legend:"Hacienda El Pino"
      },
      {
         image: require("./common/thumbnail/d87136e87d2646fbe6c8a8b02282626e_1300x1300.jpg"),
         legend:"Altovalsol"
      },
      {
         image: require("./common/thumbnail/e0d3238515c8cbf179028f67e75fcb36_1300x1300.jpg"),
         legend:"Altovalsol"
      },
      {
         image: require("./common/thumbnail/daea13ec66f7bdf360fada448291050d_1300x1300.jpg"),
         legend:"Juan Soldado"
      },
      {
         image: require("./common/thumbnail/e20f64b9e8e664e3143cfecf42c6ebbf_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/e527998bc52334e3b5fd1624e33670ab_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/e6827c921133b20364a67a781face728_1300x1300.jpg"),
         legend:"Las Torcazas"
      },
      {
         image: require("./common/thumbnail/e6cd3d3a0578d55c6ce577926c95c1cd_1300x1300.jpg"),
         legend:"El Solar de Peñuelas"
      },
      {
         image: require("./common/thumbnail/f0431de590781bf3f59bc1b816a73ca4_1300x1300.jpeg"),
         legend:"Showroom"
      },
      {
         image: require("./common/thumbnail/f48df08e6b78c4abc38e9e6bebe7ad2a_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/f681e53b5cf2c0fdaa6a3e4be386bc0d_1300x1300.jpeg"),
         legend:"Covid-19"
      },
      {
         image: require("./common/thumbnail/f9673a3bc5aba2198aa411a2301c12c4_1300x1300.jpg"),
         legend:"Nova Hacienda"
      },
   ]
};