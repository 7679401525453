export const LOGOS = {
  banner: [
    require("./autoshopping_la_serena.png"),
    require("./salfa-logo-white.svg"),
    require("./constructora_ecomac.svg"),
    require("./constructora_cadel.png"),
    require("./municipalidad_la_serena.png"),
    require("./universidad_la_serena.png"),
    require("./universidad_aconcagua.png"),
    require("./universidad_santo_tomas.png"),
  ],
  square: [
    require("./universidad_amazing_grace.png"),
    require("./ministerio_educacion.png"),
    require("./ministerio_transporte.png"),
    require("./inmobiliaria_guzman.jpg"),
  ]
}